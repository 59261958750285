<template>
  <v-card
    elevation="0"
    width="100%"
    :loading="isDownloadingOriginalImage || isFaceInfoBeingLoaded || isLoadingImageToBeCropped"
  >
    <div
      v-if="isEdit && isEditMode"
      style="position: relative"
    >
      <vue-cropper
        ref="cropper"
        :key="cropperIndex"
        :src="selectedImage"
        data-cy="cropper"
        data-dd-privacy="hidden"
        :aspect-ratio="aspectRatio"
        class="transparentBackground"
        :ready="cropPreview"
        :container-style="containerStyle"
        :cropend="cropPreview"
        :zoom="cropPreview"
      />
      <v-avatar
        v-if="isChangeProfile"
        :size="avatarSize"
        class="d-flex"
        style="position: absolute; top:20px; left:20px"
      >
        <v-img
          :src="preview"
          data-dd-privacy="hidden"
        />
      </v-avatar>
    </div>
    <v-carousel
      v-else
      v-model="carrouselImageSelected"
      :height="imageSize"
      hide-delimiters
      hide-delimiter-background
      show-arrows-on-hover
      next-icon="mdi-chevron-right"
      prev-icon="mdi-chevron-left"
      style="position: relative"
    >
      <v-carousel-item
        v-for="(img, i) in images"
        :key="i"
        eager
        :style="{ height: imageSize }"
      >
        <zoomable-image
          :src="img.originalImage || img.mediumImage"
          :has-zoom="!isEditMode"
          class="cropperCarouselImage"
          @isZoomDestroyed="removeZoomedImages"
          @isZoomEnabled="saveZoomedImages(i)"
        >
          <template v-slot:default>
            <v-img
              :key="i"
              eager
              aspect-ratio="1"
              :src="img.originalImage || img.mediumImage"
              :lazy-src="img.thumbnailImage"
              class="transparentBackground"
              data-dd-privacy="hidden"
              height="100%"
              contain
              @load="onImageLoaded"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="primary lighten-1"
                  />
                </v-row>
              </template>
              <template
                v-if="img !== undefined && img.info && isToShowBoundingBox"
              >
                <v-tooltip
                  v-for="(style, idx) in boundingBoxStyles"
                  :key="idx"
                  top
                  :open-on-click="img.info.selected === idx"
                  :open-on-hover="img.info.selected === idx"
                  :color="imageColor"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      :key="idx"
                      v-bind="attrs"
                      :data-cy="`image-${i}-bounding-box-${idx}`"
                      :style="{
                        top: style.top,
                        left: style.left,
                        height: `${style.height}px`,
                        width: `${style.width}px`,
                        border: `${borderBoundingBoxColor(img.info.selected, idx)}`,
                      }"
                      style="position: absolute; cursor: pointer"
                      v-on="on"
                      @click="selectFace(idx)"
                    >
                      <span
                        v-if="img.info.selected >= 0 && img.info.selected === idx"
                        class="d-flex justify-center align-center"
                        :style="{
                          position: 'absolute',
                          background: img.info.selected === idx && imageColor,
                          bottom: '-22px',
                          width: 'max-content',
                          'min-width': '100%',
                          height: '22px',
                          'font-size': `${$vuetify.breakpoint.mobile? '12px' : '18px'}`,
                        }"
                      >
                        {{ img.info.faces[img.info.selected].bounding_box.width }}
                        x
                        {{ img.info.faces[img.info.selected].bounding_box.height }}
                      </span>
                    </div>
                  </template>
                  <span>{{ imageMessage }}</span>
                </v-tooltip>
              </template>
            </v-img>
          </template>
        </zoomable-image>
      </v-carousel-item>
      <div style="position: absolute; top: 16px; right: 16px">
        <square-button
          color="#0000004d"
          icon-color="white"
          icon-name="mdi-close"
          fab
          @clicked="$emit('close', { img: '' })"
        />
      </div>
    </v-carousel>
    <v-card-actions>
      <v-spacer />
      <template v-if="isEditMode">
        <rectangle-button
          v-if="!isEdit"
          icon="mdi-quality-high"
          :color="hasOriginalImage? 'info' : 'neutral'"
          :outlined="!hasOriginalImage"
          @clicked="downloadOriginalImage"
        >
          <div class="d-none d-md-flex">
            {{ $t('deconve.imageHighQuality') }}
          </div>
        </rectangle-button>

        <rectangle-button
          v-if="!isEdit"
          outlined
          data-cy="cropper-button-delete-faces"
          icon="mdi-square-off-outline"
          color="neutral"
          @clicked="deleteFaces"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.person.deleteFaces') }}
          </div>
        </rectangle-button>
        <rectangle-button
          v-if="!isEdit"
          outlined
          data-cy="cropper-button-detect-faces"
          icon="mdi-face-recognition"
          color="neutral"
          @clicked="detectFaces"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.person.detectFaces') }}
          </div>
        </rectangle-button>
        <rectangle-button
          v-if="!isSuperProfile && !isEdit"
          outlined
          data-cy="cropper-button-set-profile"
          icon="mdi-account-outline"
          color="neutral"
          @clicked="setChangeProfile"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.person.setProfile') }}
          </div>
        </rectangle-button>
        <rectangle-button
          v-if="isEdit"
          outlined
          data-cy="cropper-button-reset"
          icon="mdi-reload"
          color="info"
          @clicked="reset"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.reset') }}
          </div>
        </rectangle-button>
        <rectangle-button
          v-if="isEdit"
          data-cy="cropper-button-cancel"
          outlined
          icon="mdi-close"
          color="warn"
          @clicked="setClose"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.cancel') }}
          </div>
        </rectangle-button>
        <rectangle-button
          v-if="!isSuperProfile && !isEdit"
          color="warn"
          icon="mdi-close"
          data-cy="cropper-button-remove"
          outlined
          @clicked="$emit('remove', index)"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.delete') }}
          </div>
        </rectangle-button>
        <rectangle-button
          v-if="!isSuperProfile"
          color="primary"
          data-cy="cropper-button-crop"
          icon="mdi-crop"
          @clicked="crop"
        >
          <div class="d-none d-sm-none d-xs-none d-md-flex">
            {{ $t('deconve.crop') }}
          </div>
        </rectangle-button>
      </template>
      <template v-else>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              outlined
              :disabled="isZoomEnabled"
              color="neutral"
              v-bind="attrs"
              :small="isMobileVersion"
              v-on="on"
              @click="setBoundingBoxStatus"
            >
              <v-icon :small="isMobileVersion">
                {{ showBoundingBox? 'mdi-square-off-outline' : 'mdi-square-outline' }}
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{
              showBoundingBox?
                $t('deconve.notification.unmarkFace') :
                $t('deconve.notification.markFace')
            }}
          </span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-2"
              :color="hasOriginalImage? 'info' : 'neutral'"
              :outlined="!hasOriginalImage"
              elevation="0"
              v-bind="attrs"
              :small="isMobileVersion"
              v-on="on"
              @click="downloadOriginalImage"
            >
              <v-icon :small="isMobileVersion">
                mdi-quality-high
              </v-icon>
            </v-btn>
          </template>
          <span>
            {{ $t('deconve.imageHighQuality') }}
          </span>
        </v-tooltip>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
// Copyright (C) 2021 Deconve Technology. All rights reserved.

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { mapActions, mapGetters } from 'vuex';
import RectangleButton from './RectangleButton.vue';
import SquareButton from './SquareButton.vue';
import ZoomableImage from './ZoomableImage.vue';
import boundingBoxCalc from '../utils/boundingBoxCalc';
import imageResolutionScore, {
  NOT_ACCEPTED_RESOLUTION_IMAGE,
  LOW_RESOLUTION_IMAGE,
  GOOD_RESOLUTION_IMAGE,
} from '../utils/imageResolutionScore';

export default {
  name: 'ImageCropperEditor',
  components: {
    VueCropper,
    RectangleButton,
    SquareButton,
    ZoomableImage,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
    index: { type: Number, required: true },
    isEditMode: { type: Boolean, required: true },
    isSuperProfile: { type: Boolean, default: false },
  },
  data: () => ({
    aspectRatio: undefined,
    isEdit: false,
    zoomedImages: [],
    lastImageSaved: {},
    isChangeProfile: false,
    preview: '',
    cropperIndex: 0,
    carrouselImageSelected: 0,
    imageColor: '',
    imageMessage: '',
    isLoadingImageToBeCropped: false,
    isDownloadingOriginalImage: false,
    selectedImage: '',
    imageContainers: [],
    boundingBoxStyles: [],
    showBoundingBox: true,
    containerStyle: {
      width: '100%',
      height: '65vh',
      display: 'flex',
      'justify-content': 'center',
      'border-radius': '4px',
      overflow: 'hidden',
    },
  }),
  computed: {
    ...mapGetters({
      facesAreBeingLoaded: 'faceid/facesAreBeingLoaded',
      personImageInfoByIndex: 'faceid/personImageInfoByIndex',
    }),
    isZoomEnabled() {
      return this.zoomedImages.some((index) => index === this.carrouselImageSelected);
    },
    borderBoundingBoxColor() {
      return (selected, idx) => (selected === idx ? `1px solid ${this.imageColor}` : '1px solid red');
    },
    isToShowBoundingBox() {
      return this.boundingBoxStyles && this.showBoundingBox && !this.isZoomEnabled;
    },
    isFaceInfoBeingLoaded() {
      return this.facesAreBeingLoaded(this.carrouselImageSelected);
    },
    info() {
      return this.personImageInfoByIndex(this.carrouselImageSelected);
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
    hasOriginalImage() {
      return this.images[this.carrouselImageSelected]?.originalImage !== '';
    },
    avatarSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 100;
        case 'sm': return 150;
        case 'md': return 200;
        case 'lg': return 200;
        case 'xl': return 200;
        default: return 200;
      }
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 258;
        case 'sm': return 448;
        case 'md': return 516;
        case 'lg': return 516;
        case 'xl': return 612;
        default: return 258;
      }
    },
  },
  watch: {
    index(index) {
      this.isEdit = false;
      this.isChangeProfile = false;
      this.carrouselImageSelected = index;
    },
    carrouselImageSelected(index) {
      if (index >= 0) {
        const image = this.images[index];

        this.lastImageSaved = image;
        this.$emit('changed', index);

        if (image.originalImage === '' && image.mediumImage === '') {
          this.getMediumPersonImage(index);
        }
      }
    },
    images() {
      if (this.lastImageSaved) {
        this.updateImageIndexWithLastImageSaved();
      }

      this.imageContainers = document.getElementsByClassName('cropperCarouselImage');

      if (this.isImageSorted) { this.updateImageIndexWithLastImageSaved(); }
    },
    info() {
      this.getImageBoundingBox();
    },
  },
  created() {
    window.addEventListener('resize', this.getImageBoundingBox);
    this.imageContainers = document.getElementsByClassName('cropperCarouselImage');
    this.carrouselImageSelected = this.index;
    const image = this.images[this.index];

    if (image.originalImage === '' && image.mediumImage === '') {
      this.getMediumPersonImage(this.index);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getImageBoundingBox);
  },
  methods: {
    ...mapActions({
      sortPersonImages: 'faceid/sortPersonImages',
      getMediumPersonImage: 'faceid/getMediumPersonImage',
      getOriginalPersonImage: 'faceid/getOriginalPersonImage',
    }),
    removeZoomedImages() {
      this.zoomedImages = [];
    },
    saveZoomedImages(indexImage) {
      const positionOnList = this.zoomedImages.indexOf(indexImage);

      if (positionOnList >= 0) {
        this.zoomedImages.splice(positionOnList, 1);
        return;
      }

      this.zoomedImages.push(indexImage);
    },
    setBoundingBoxStatus() {
      this.showBoundingBox = !this.showBoundingBox;
    },
    onImageLoaded() {
      this.getImageBoundingBox();
    },
    crop() {
      this.isLoadingImageToBeCropped = true;

      this.downloadOriginalImage(this.carrouselImageSelected).then(() => {
        if (!this.isEdit) {
          this.selectImageToCrop();
        } else {
          this.isEdit = false;
          this.isLoadingImageToBeCropped = false;

          if (this.isChangeProfile) {
            this.changeProfile();
          } else {
            this.cropImage();
          }
        }
      });
    },
    deleteFaces() {
      this.downloadOriginalImage(this.carrouselImageSelected).then(() => {
        this.boundingBoxStyles = [];
        this.$emit('deleteFaces', this.carrouselImageSelected);
      });
    },
    detectFaces() {
      this.downloadOriginalImage(this.carrouselImageSelected).then(() => {
        this.$emit('detectFaces', this.carrouselImageSelected);
      });
    },
    cropImage() {
      const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
      const croppedImage = croppedCanvas.toDataURL('image/png');

      const { height, width } = croppedCanvas;
      const imageData = { height, width, image: croppedImage };

      this.$emit('close', { imageData, index: this.index });
    },
    changeProfile() {
      // profile image is saved jpeg format
      const croppedCanvas = this.$refs.cropper.getCroppedCanvas();
      const croppedImage = croppedCanvas.toDataURL('image/jpg');

      const { height, width } = croppedCanvas;
      const imageData = { height, width, image: croppedImage };

      this.$emit('close', { imageData });
      this.aspectRatio = undefined;
    },
    updateImageIndexWithLastImageSaved() {
      this.boundingBoxStyles = [];
      this.carrouselImageSelected = this.images.indexOf(this.lastImageSaved);
    },
    setChangeProfile() {
      this.aspectRatio = 1;
      this.isChangeProfile = true;
      this.downloadOriginalImage(this.carrouselImageSelected).then(() => {
        this.selectImageToCrop();
      });
    },
    setClose() {
      this.isEdit = false;
      this.isChangeProfile = false;
      this.aspectRatio = undefined;
    },
    sortImages() {
      this.sortPersonImages();
    },
    cropPreview() {
      this.preview = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    reset() {
      this.$refs.cropper.reset();
    },
    selectFace(index) {
      const { selected: currentFaceIndex } = this.personImageInfoByIndex(
        this.carrouselImageSelected,
      );

      if (this.isEditMode && currentFaceIndex !== index) {
        // if a selected face already exist, it reset the bounding box border color to red.
        this.$emit('selectFace', { imageIndex: this.carrouselImageSelected, faceIndex: index });
        this.getImageInfo();
      }
    },
    selectImageToCrop() {
      // Croppejs recomends to crop the image to avoid crash the browser on iOS.
      const image = new Image();
      const maxWidth = 512;
      const selectedImage = this.images[this.carrouselImageSelected];
      const selectedImageWithoutResize = selectedImage.originalImage;

      image.onload = (e) => {
        if (e.target.width > maxWidth || e.target.height > maxWidth) {
          const elem = document.createElement('canvas'); // Create a canvas

          // Scale the image to maxWidth and keep aspect ratio
          const scaleFactor = maxWidth / e.target.width;

          elem.width = maxWidth;
          elem.height = e.target.height * scaleFactor;

          // Draw in canvas
          const ctx = elem.getContext('2d');

          ctx.drawImage(
            e.target, 0, 0, elem.width, elem.height,
          );

          // Get the base64-encoded Data URI from the resize image
          const resizedImage = ctx.canvas.toDataURL(e.target, 'image/png', 0);

          this.selectedImage = resizedImage;

          // The edit mode should be activated when the selected image is changed
          this.isEdit = true;
        }

        this.isLoadingImageToBeCropped = false;
        this.selectedImage = selectedImageWithoutResize;

        // The edit mode should be activated when the selected image is changed
        this.isEdit = true;
      };

      // When the selected image is changed, the cropper needs to be refreshed with a new index
      this.cropperIndex += 1;
      image.src = selectedImageWithoutResize;
    },
    downloadOriginalImage() {
      return new Promise((resolve) => {
        if (!this.hasOriginalImage) {
          this.isDownloadingOriginalImage = true;

          this.getOriginalPersonImage(this.carrouselImageSelected).then(() => {
            this.isDownloadingOriginalImage = false;
            resolve();
          }).catch(() => {
            this.isDownloadingOriginalImage = false;
          });
        } else {
          resolve();
        }
      });
    },
    getImageBoundingBox() {
      this.boundingBoxStyles = [];
      const selectedImage = this.images[this.carrouselImageSelected];

      const imageInfo = selectedImage?.info;

      if (imageInfo) {
        const img = new Image();

        img.onload = () => {
          const { height, width } = img;

          const imageContainerSelected = this.imageContainers[this.carrouselImageSelected];

          if (imageContainerSelected) {
            const { faces } = imageInfo;

            if (faces && faces.length > 0) {
              this.boundingBoxStyles = [];

              faces.forEach((face) => {
                this.boundingBoxStyles.push(boundingBoxCalc({
                  height: selectedImage.originalHeight || height,
                  width: selectedImage.originalWidth || width,
                  currentImageInfo: face.bounding_box,
                  currentImageContainer: imageContainerSelected,
                }));
              });

              this.getImageInfo();
            }
          }
        };

        img.src = selectedImage.originalImage || selectedImage.mediumImage;
      }
    },
    getImageInfo() {
      const selectedImage = this.images[this.carrouselImageSelected];
      const hasFaceSelected = selectedImage.info?.selected >= 0;

      if (hasFaceSelected) {
        const face = selectedImage.info.faces[selectedImage.info.selected].bounding_box;
        const resolution = imageResolutionScore(face.width, face.height);

        if (resolution === LOW_RESOLUTION_IMAGE) {
          this.imageColor = this.$vuetify.theme.currentTheme.lowResolutionColor;
          this.imageMessage = this.$t('deconve.person.image.resolution.low');
        } else if (resolution === NOT_ACCEPTED_RESOLUTION_IMAGE) {
          this.imageColor = this.$vuetify.theme.currentTheme.notAcceptedResolutionColor;
          this.imageMessage = this.$t('deconve.person.image.resolution.notAccepted');
        } else if (resolution === GOOD_RESOLUTION_IMAGE) {
          this.imageColor = this.$vuetify.theme.currentTheme.goodResolutionColor;
          this.imageMessage = this.$t('deconve.person.image.resolution.good');
        } else {
          this.imageColor = '';
          this.imageMessage = '';
        }
      }
    },
  },
};
</script>

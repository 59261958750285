<template>
  <v-card
    elevation="0"
    class="px-sm-4 pb-4 pb-sm-4"
    style="width: 100%; border: 1px solid"
    :style="{
      borderColor: $vuetify.theme.themes.light.border,
      backgroundColor: $vuetify.theme.themes.light.boxBackground
    }"
  >
    <v-row
      style="min-width: 100%"
    >
      <v-col
        :cols="isMobileVersion ? 12 : 6"
        max-width="885px"
        :class="{'pl-1 pr-3 py-4': !isMobileVersion}"
      >
        <div
          class="d-flex flex-wrap align-center"
          :class="{'justify-start': imageSize < 300,
                   'profileTitle': isMobileVersion,
                   'mt-4': !isMobileVersion}"
        >
          <div
            v-if="!isLoadingSimilarSuperProfileImage && similarSuperProfileImages.length > 0"
            class="d-flex"
            :class="{'mr-4 align-center': !isMobileVersion, 'align-start': isMobileVersion}"
          >
            <v-icon
              class="mr-2 d-sm-flex"
              color="textBlack"
              :size="isMobileVersion ? 20 : 24"
            >
              {{ icons.account }}
            </v-icon>
            <h5
              class="d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text"
              style="word-break: break-word; line-height: 1;"
              :style="{ 'max-width': imageSize < 300 ? '165px' : 'initial' }"
            >
              {{ currentSuperProfileName }}
            </h5>
          </div>
          <div
            v-else
            class="d-none d-sm-flex align-center mr-4"
          >
            <v-skeleton-loader
              type="text"
              class="mr-2 d-none d-md-flex"
              width="100"
            />
          </div>
        </div>
        <carousel
          class="sideBySide"
          :images="currentSuperProfileImages"
          :index="currentSuperProfileImageIndex"
          :number-of-images="currentSuperProfileImages.length"
          :show-face-preview="false"
          :show-add-to-profile="false"
          :loading="isLoadingCurrentSuperProfileImage"
          card-border-color
          is-profile-carousel
          :carousel-height="'486px'"
          @downloadOriginalImage="downloadCurrentSuperProfileOriginalImage"
          @changeCarouselImageIndex="changeCurrentSuperProfileImageIndex"
        />
      </v-col>

      <v-col
        :cols="isMobileVersion ? 12 : 6"
        max-width="885px"
        :class="{'pl-1 pr-3 py-4': !isMobileVersion}"
      >
        <div
          class="d-flex flex-wrap align-center"
          :class="{'justify-start': imageSize < 300,
                   'profileTitle': isMobileVersion,
                   'mt-4': !isMobileVersion}"
        >
          <div
            v-if="!isLoadingSimilarSuperProfileImage && similarSuperProfileImages.length > 0"
            class="d-flex"
            :class="{'mr-4 align-center': !isMobileVersion, 'align-start': isMobileVersion}"
          >
            <v-icon
              class="mr-2 d-sm-flex"
              color="textBlack"
              :size="isMobileVersion ? 20 : 24"
            >
              {{ icons.account }}
            </v-icon>
            <h5
              class="d-flex mr-4 text-h6 text-sm-h5 font-weight-bold textBlack--text"
              style="word-break: break-word; line-height: 1; cursor: pointer;"
              :style="{ 'max-width': imageSize < 300 ? '165px' : 'initial' }"
              @click="goToSuperProfilePage"
            >
              {{ similarSuperProfileName }}
            </h5>
          </div>
          <div
            v-else
            class="d-none d-sm-flex align-center mr-4"
          >
            <v-skeleton-loader
              type="text"
              class="mr-2 d-none d-md-flex"
              width="100"
            />
          </div>
        </div>
        <carousel
          class="sideBySide"
          :images="similarSuperProfileImages"
          :index="similarSuperProfileImageIndex"
          :number-of-images="similarSuperProfileImages.length"
          :show-face-preview="false"
          :show-add-to-profile="false"
          :is-similar-super-profile="true"
          :is-profile-carousel="false"
          :loading="isLoadingSimilarSuperProfileImage"
          card-border-color
          :carousel-height="'486px'"
          @downloadOriginalImage="downloadSimilarSuperProfileOriginalImage"
          @changeCarouselImageIndex="changeSimilarSuperProfileImageIndex"
          @mergeSuperProfile="mergeSuperProfile"
        />
      </v-col>

      <div style="position: absolute; top: 10px; right: 10px">
        <square-button
          color="#0000004d"
          icon-color="white"
          icon-name="mdi-close"
          fab
          @clicked="$emit('close')"
        />
      </div>
    </v-row>

    <confirmation-dialog ref="confirm" />
  </v-card>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { mdiAccountBoxOutline } from '@mdi/js';
import Carousel from '@/components/Carousel.vue';
import ConfirmationDialog from './ConfirmationDialog.vue';
import SquareButton from './SquareButton.vue';

export default {
  name: 'SimilarSuperProfileImageViewer',
  components: {
    Carousel,
    SquareButton,
    ConfirmationDialog,
  },
  props: {
    similarSuperProfileImages: { type: Array, required: true },
    superProfileId: { type: String, required: true },
    isSendingProfile: Boolean,
  },
  data() {
    return {
      currentSuperProfileImageIndex: 0,
      similarSuperProfileImageIndex: 0,
      icons: {
        account: mdiAccountBoxOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      superProfileImages: 'faceid/personImages',
      imageCurrentSuperProfileIsBeingLoaded: 'faceid/imageIsBeingLoaded',
      imageSimilarSuperProfileIsBeingLoaded: 'faceid/imageSimilarSuperProfileIsBeingLoaded',
      getSimilarSuperProfile: 'faceid/getSimilarSuperProfile',
      getSuperProfile: 'faceid/getSuperProfile',
    }),
    currentSuperProfileImages() {
      return this.superProfileImages;
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
    isLoadingSimilarSuperProfileImage() {
      const imageId = this.similarSuperProfileImages[this.similarSuperProfileImageIndex].id;
      const isLoading = this.imageSimilarSuperProfileIsBeingLoaded(this.superProfileId, imageId);

      return isLoading || this.isSendingProfile;
    },
    isLoadingCurrentSuperProfileImage() {
      const isLoading = this.imageCurrentSuperProfileIsBeingLoaded(
        this.currentSuperProfileImageIndex,
      );

      return isLoading || this.isSendingProfile;
    },
    similarSuperProfileName() {
      return this.getSimilarSuperProfile(this.superProfileId)?.name;
    },
    currentSuperProfileName() {
      const { superProfileId } = this.$route.params;

      return this.getSuperProfile(superProfileId)?.name;
    },
    imageSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 258;
        case 'sm': return 448;
        case 'md': return 516;
        case 'lg': return 516;
        case 'xl': return 612;
        default: return 258;
      }
    },
  },
  watch: {
    superProfileId() {
      this.similarSuperProfileImageIndex = 0;
    },
  },
  methods: {
    ...mapActions({
      getSimilarSuperProfileOriginalImage: 'faceid/getOriginalSuperProfileImage',
      getCurrentSuperProfileOriginalImage: 'faceid/getOriginalPersonImage',
    }),
    downloadCurrentSuperProfileOriginalImage(imageIndex) {
      const { originalImage: hasOriginalImage } = this.currentSuperProfileImages[imageIndex];

      if (!hasOriginalImage) {
        this.getCurrentSuperProfileOriginalImage(imageIndex);
      }
    },
    downloadSimilarSuperProfileOriginalImage(imageIndex) {
      const imageSelected = this.similarSuperProfileImages[imageIndex];
      const { id: imageId, originalImage: hasOriginalImage } = imageSelected;

      if (!hasOriginalImage) {
        const imageData = { superProfileId: this.superProfileId, imageId };

        this.getSimilarSuperProfileOriginalImage(imageData);
      }
    },
    changeCurrentSuperProfileImageIndex(imageIndex) {
      this.currentSuperProfileImageIndex = imageIndex;
    },
    changeSimilarSuperProfileImageIndex(imageIndex) {
      this.similarSuperProfileImageIndex = imageIndex;
    },
    mergeSuperProfile() {
      this.$refs.confirm.open(
        this.$t('deconve.submit'),
        this.$t('deconve.superProfilesMergeConfirmation'),
      ).then((confirm) => {
        if (confirm) this.$emit('mergeSuperProfile');
      });
    },
    goToSuperProfilePage() {
      this.$router.push({ name: 'faceid-super-profile', params: { superProfileId: this.superProfileId } });
    },
  },
};
</script>

<style scoped>
.similar-super-profile-image-viewer {
  width: 100%;
}

.v-row {
  display: flex;
}

.v-col {
  flex: 1;
}

.profileTitle {
    box-sizing: content-box !important;
    width: 100% !important;
    padding-top: 20px;
    padding-left: 16px;
    @media (max-width: 500px) {
      padding-top: 4px;
      padding-left: 24px;
    }
  }

.sideBySide {
    @media (max-width: 1024px) {
      padding-right: 12px;
      padding-left: 12px;
    }
    @media (max-width: 500px) {
      padding-right: 24px;
      padding-left: 24px;
      padding-bottom: 12px
    }
  }
</style>

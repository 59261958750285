var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex align-start",attrs:{"elevation":"0"}},[_c('v-card',{style:({
      height: _vm.$vuetify.breakpoint.mobile? '80px' : '100px',
      width: _vm.$vuetify.breakpoint.mobile? '60px' : '80px',
      overflow: 'hidden',
      padding: '2px',
    }),attrs:{"elevation":"0"},on:{"click":function($event){return _vm.$emit('onFaceSelected', _vm.index)}}},[_c('v-img',{ref:"face",staticClass:"rounded grey lighten-2 d-flex align-end ",staticStyle:{"width":"100%","height":"100%","object-fit":"cover"},attrs:{"src":_vm.croppedImage,"data-dd-privacy":"hidden"}})],1),_c('div',[(!_vm.loadingSimilar && _vm.similarPeople.length > 0)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ma-1 cursor-pointer",staticStyle:{"cursor":"pointer"},attrs:{"color":"warning","small":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.openSimilarPeopleDialog}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlert)+" ")])]}}],null,false,2414083074)},[_c('span',[_vm._v(" "+_vm._s(_vm.$tc('deconve.person.identifiedPeople', _vm.similarPeople.length))+" ")])]):(_vm.loadingSimilar)?[_c('span',{staticClass:"fill-height ma-0"},[_c('v-progress-circular',{attrs:{"size":"16","width":"2","indeterminate":"","color":"grey lighten-1"}})],1)]:_vm._e()],2),_c('similar-people-dialog',{ref:"similarDialog",attrs:{"is-loading":_vm.loadingSimilar}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-col
    width="100%"
  >
    <v-row
      align-content="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        v-if="!isLoading"
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 16 : 20"
        >
          {{ icons.similarSuperProfiles }}
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ $tc('deconve.similarSuperProfilesFound', similarSuperProfilesList.length) }}
        </div>
      </div>
      <v-skeleton-loader
        v-else
        type="heading"
        style="flex: 1"
        max-width="300px"
      />
    </v-row>

    <v-row>
      <v-col
        v-for="(superProfile) in similarSuperProfilesList"
        :key="superProfile.id"
        cols="auto"
        class="ma-2"
      >
        <similar-super-profile-list-face
          :super-profile-id="superProfile.id"
          @showSimilarProfile="showSimilarProfile"
        />
      </v-col>
    </v-row>

    <v-row v-if="isToShowSimilarProfile && superProfileSelected">
      <v-col cols="12">
        <similar-super-profile-image-viewer
          :similar-super-profile-images="similarSuperProfileImagesSelected"
          :super-profile-id="superProfileSelected"
          :is-sending-profile="isSendingProfile"
          @close="isToShowSimilarProfile = false"
          @mergeSuperProfile="mergeSuperProfile"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
// Copyright (C) 2024 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { mdiAccountMultipleOutline } from '@mdi/js';
import SimilarSuperProfileImageViewer from './SimilarSuperProfileImageViewer.vue';
import SimilarSuperProfileListFace from './SimilarSuperProfileListFace.vue';

export default {
  name: 'SimilarSuperProfilesList',
  components: {
    SimilarSuperProfileListFace,
    SimilarSuperProfileImageViewer,
  },
  props: {
    similarSuperProfilesList: { type: Array, required: true },
    superProfileId: { type: String, required: true },
    isLoading: Boolean,
  },
  data() {
    return {
      isToShowSimilarProfile: false,
      superProfileSelected: '',
      isSendingProfile: false,
      icons: {
        similarSuperProfiles: mdiAccountMultipleOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      superProfileData: 'faceid/superProfileData',
      similarSuperProfilesImages: 'faceid/superProfileImages',
      getSimilarSuperProfile: 'faceid/getSimilarSuperProfile',
    }),
    similarSuperProfileImagesSelected() {
      const { images } = this.similarSuperProfilesImages
        .find((profile) => profile.superProfileId === this.superProfileSelected);

      return images;
    },
    selectedSuperProfileData() {
      return this.getSimilarSuperProfile(this.superProfileSelected);
    },
    selectedSuperProfileCreatedAt() {
      return this.selectedSuperProfileData?.createdAt;
    },
  },
  methods: {
    ...mapActions({
      fetchSuperProfile: 'faceid/fetchSuperProfile',
      fetchSuperProfileHelper: 'faceid/fetchSuperProfileHelper',
      editSuperProfile: 'faceid/editSuperProfile',
    }),
    showSimilarProfile(superProfileSelected) {
      this.isToShowSimilarProfile = true;
      this.superProfileSelected = superProfileSelected;
    },
    mergeSuperProfile() {
      this.isSendingProfile = true;

      this.fetchSuperProfileHelper(this.superProfileSelected).then((otherSuperProfileData) => {
        const { created_at: createdAt } = this.superProfileData;
        const { created_at: otherSuperProfileCreatedAt } = otherSuperProfileData;

        const isCurrentProfileOldest = new Date(createdAt) < new Date(otherSuperProfileCreatedAt);

        let mainSuperProfile;
        let superProfileToMerge;

        if (isCurrentProfileOldest) {
          mainSuperProfile = this.superProfileId;
          superProfileToMerge = this.superProfileSelected;
        } else {
          mainSuperProfile = this.superProfileSelected;
          superProfileToMerge = this.superProfileId;
        }

        const formData = new FormData();

        formData.append('super_profiles_to_merge', superProfileToMerge);

        this.editSuperProfile({ superProfileId: mainSuperProfile, payload: formData })
          .then(() => {
            this.isSendingProfile = false;

            if (isCurrentProfileOldest) {
              this.fetchSuperProfile(this.superProfileId).then(() => {
                this.$emit('edited');
                this.isToShowSimilarProfile = false;
              });
            } else {
              this.$router.push({
                name: 'faceid-super-profile',
                params: { superProfileId: mainSuperProfile },
              });
            }
          }).catch((error) => {
            this.error = error;
            this.isSendingProfile = false;
            this.isToShowSimilarProfile = false;
          });
      }).catch((error) => {
        this.error = error;
        this.isSendingProfile = false;
        this.isToShowSimilarProfile = false;
      });
    },
  },
};

</script>
